* a {
	text-decoration: none !important;
}

body {
	-webkit-font-smoothing: antialiased;
	font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Arial",
		sans-serif;
	text-rendering: optimizeLegibility;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	text-rendering: optimizeLegibility;
	/* font-size: 14px; */
	height: 100%;
}

@media only screen and (min-width: 320px) {
	:root {
		font-size: 12px;
	}
}

@media only screen and (min-width: 768px) {
	:root {
		font-size: 14px;
	}
}

/* @media only screen and (min-width: 1440px) {
	:root {
		font-size: 20px;
	}
} */

ul,
ol {
	list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Arial",
		sans-serif;
	font-weight: 700;
	letter-spacing: 0;
	color: #212529;
}

h1 {
	font-size: 24px;
}

h2 {
	font-size: 18px;
}

h3 {
	font-size: 16px;
}

h4 {
	font-size: 14px;
}

h5 {
	font-size: 12px;
}

h6 {
	font-size: 10px;
}

input::placeholder {
	font-family: "Lato", sans-serif !important;
}

.App {
	position: relative;
}

/* React notifications css override */
.rnc__base {
	z-index: 999999 !important;
}

.rnc__notification-container--top-right {
	top: 60px;
}
